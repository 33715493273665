import React, { Component } from 'react';
import { HashLink as HLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import ScrollLink from './ScrollLink';

class Header extends Component {

   render() {
      let imgattr = 'image'

      return (
      <div>
         <header id="home">
            <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header" style={{ backgroundColor: "white", borderBottom: '1px solid lightgrey'}}>
               <div className="container">
                  <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                     <img src={process.env.PUBLIC_URL + "/assets/img/Darul-Hijrah logo blk.png" } alt={imgattr} width={80} height={75} />
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
                  <i className="icofont-navigation-menu"></i>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarCodeply">
                     <ul className="nav navbar-nav ml-auto" >
                        <li ><HLink className="nav-link" smooth to={`/`} >Laman Utama</HLink>
                           <span className="sub-menu-toggle" role="navigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="icofont-rounded-down" style={{color:'green'}}></i>
                           </span>
                           <ul className="sub-menu sub-menu-1">
                              <li><HLink className='nav-link' to='/Blogfull'>Perutusan Pengerusi & Mudir</HLink></li>
                           </ul>
                        </li>

                        <li>
                           <ScrollLink className="nav-link" to="aboutUs">Darul Hijrah</ScrollLink>

                           <span className="sub-menu-toggle" role="navigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="icofont-rounded-down" style={{color:'green'}}></i>
                           </span>
                           <ul className="sub-menu sub-menu-2">
                              <li><HLink smooth to={'about-2'}>Pertubuhan Darul Hijrah</HLink></li>
                              <li><HLink smooth to={`about-2/#ourValues`}>Madrasah Darul Hijrah</HLink></li>
                              <li><HLink smooth to={`about-2/#ourSet-up`}>Carta Organisasi</HLink></li>
                           </ul>
                        </li>  
                                          
                        {/* <li>
                           <ScrollLink className="nav-link" to="news" >Berita & Galeri</ScrollLink>
                        </li> */}

                        <li>
                           <ScrollLink className="nav-link" to="news" >Berita & Galeri</ScrollLink>

                           <span className="sub-menu-toggle" role="navigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="icofont-rounded-down" style={{color:'green'}}></i>
                           </span>
                           <ul className="sub-menu sub-menu-3">
                              <li><Link to="/berita-aktiviti">Berita & Aktiviti</Link></li>
                              <li><HLink to="/htap">Galeri</HLink></li>
                              {/* <li><HLink to="/organisai">Carta Organisai</HLink></li> 
                              <li><HLink to="/single-right-sidebar">Single right sidebar</HLink></li> */}
                           </ul>
                        </li>
                        
                        {/* <li><HLink className="nav-link" to={`/sertu`} >e-sertu</HLink></li> */}

                        <li><ScrollLink className="nav-link" to="contact" >Hubungi Kami</ScrollLink></li>
                     </ul>
                  </div>
               </div>
            </nav>
         </header>      
      </div>

    );
   }
}

export default Header;
