import React, { Component } from 'react';
import Feature from '../components-home/Feature';
import FeatureOne from '../components-home/FeatureOne';
import FeatureTwo from '../components-home/FeatureTwo';
import Testimonials from '../components-home/Testimonials';
import Howitworks from '../components-home/Howitworks';
import HeaderBottom from '../components-global/Header-bottom-about';
import Header3 from "../components-global/Header3";
import Screenshots from '../components-home/Screenshots';

class About extends Component {

  render() {
   let imgattr = 'image'
   let publicUrl = process.env.PUBLIC_URL
   let propsData = this.props.data
   let blogsdata = propsData.singlepostdata
   let headerPageTitle = propsData.pagetitle.singleblogtitle
  
    return (
      <div>
         <Header3 />
         <HeaderBottom headertitle={headerPageTitle} />
         <FeatureOne />
          <Howitworks />
          {/* <Services /> */}
          <FeatureTwo />
          {/* <Feature /> */}
          {/* <Preface /> */}
          {/* <Blog /> */}
          {/* <Screenshots /> */}
          {/* <Testimonials /> */}
         
         
      </div>
    );
  }
}

export default About;


