import React, { Component } from 'react';
import homedata from '../../data/homedata.json';

class FeatureTwo extends Component {
  render() {
   let publicUrl = process.env.PUBLIC_URL
   let imgattr = 'image'
    return (
      // <section id="ourSet-up" className="p-100px-tb sm-p-50px-tb featured-2 position-relative gray-bg" style={{minHeight:'100vh'}}>
      <section id="ourSet-up" className="p-100px-tb md-p-80px-tb featured-1 position-relative" style={{minHeight:'100vh'}}>
         <div className="bordered-circle2 "></div>
         <div className="container p-20px-t" style={{paddingBottom:"40vh"}}>
            <div className="row justify-content-center align-items-center">
               <div className="col-md-8 " style={{ textAlign: 'center' }}>
                  <h2 style={{fontSize:'40px'}}>{homedata.feature2.title}</h2>
                  {/* <p className="about-content">{homedata.feature2.content}</p>
                  <div className="apps-buttons">
                     <a href={homedata.feature2.btn1url} className="btn btn-default btn-filled  animated-btn"><i className="icofont-brand-android-robot"></i> <span dangerouslySetInnerHTML={{__html: homedata.feature2.btn1label}}></span></a>
                     <a href={homedata.feature2.btn2url} className="btn btn-default  btn-filled animated-btn"><i className="icofont-brand-apple"></i> <span dangerouslySetInnerHTML={{__html: homedata.feature2.btn2label}}></span></a>
                  </div> */}
               </div> 
               <div className="col-md-8" style={{ textAlign: 'center' }}>
                  <div className="featured-mockup2">
                     <img className="phone-1" src={publicUrl+homedata.feature2.imgurl1} alt={imgattr}/>
                  </div>
               </div>
            </div>
         </div>
      </section>

    );
  }
}

export default FeatureTwo;
