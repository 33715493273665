import React, { Component } from 'react';
import homedata from '../../data/homedata.json';

class Howitworks extends Component {
  render() {
    return (
      <section id="ourValues" className="p-100px-tb gray-bg" >
         <div className="container p-15px-t">
            <div className="row">
               <div className="col-sm-8 offset-sm-2 ">
                  <div className="text-center section-title text-center m-50px-b">
                     <h3>{homedata.howitworks.sectiontitle}</h3>
                     <h3>{homedata.howitworks.title}</h3>
                     <p><strong>{homedata.howitworks.content}</strong> </p>
                  </div>
               </div>
            </div>
            <div className="row">
               { homedata.howitworks.features.map( ( feature, i ) => {
                  return <div key={i} className="col-sm-6 col-xs-12 d-flex align-items-stretch">
                        <div className="how-it-box text-center p-15px-lr p-50px-tb m-10px-t m-10px-b ">
                           <i className={feature.icon}></i>
                           <h4>{feature.title}</h4>
                           <p>{feature.content}</p>
                        </div>
                     </div>
                 } )
               }
            </div>
         </div>
      </section>
// col-md-6 col-xs-12 d-flex align-items-stretch
    );
  }
}

export default Howitworks;
