import React, { Component } from 'react';
import Header2 from "../components-global/Header2";
import HeaderBottom from '../components-global/Header-bottom-htap';

class HTAP extends Component {

   render() {
      let anchor = '#'
      let propsData = this.props.data
      let blogs = propsData.blogs
      let headerPageTitle = propsData.pagetitle.blogfulltitle
      
      return (
      <div>
      <Header2 />
      <HeaderBottom />
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container ">
            <div className="row">
            { blogs.map( ( bloginfo,  ) => {

               let col = 'col-lg-6';
               if( bloginfo.fullwidth ) {
                  col = 'col-lg-12';
               }
               
               return (
                  
                  
                     <article className="page-content">
                        <div className="blog-content">
                              <h3>Ahad , 4hb Ogos 2024 - Bangunan dan persekitaran Madrasah Darul Hijrah.</h3>
                              {/* <p><b>Ahad , 4hb Ogos 2024 - Bangunan dan persekitaran Madrasah Darul Hijrah.</b></p> */}

                              {/* <div className="single-blog-post " style={{textAlign:'justify'}}>
                                 <p>The Halalan-Toyyiban Assurance Pipeline (HTAP) establishes a connection between product producers and consumers, ensuring that goods meet the requirements of halalan-toyyiban (permissible and wholesome) standards throughout the supply chain.</p>
                                 <p><b>This pipeline is translated into a set of Management System Requirements Standards, including:</b></p>
                                 <p>1.	Transportation of goods and cargo chain services</p>
                                 <p>2.	Warehousing and related activities</p>
                                 <p>3.	Retailing</p>
                                 <p><b>To implement these standards, organizations must adhere to the principles of the following standards:</b></p>
                                 <p>1.	MS 2300 - Value-based management systems: This standard outlines the requirements for an Islamic-based management system, ensuring organizations operate with integrity to uphold the Halalan-Toyyiban Assurance Pipeline.</p>
                                 <p>2.	MS 1900 - Quality management systems from Islamic perspectives: This standard helps organizations integrate Shariah requirements into their quality management practices, focusing on value-based management.</p>
                                 <p>3.	MS 1500 - Halal food guidelines: Providing practical guidance for the food industry, this standard ensures halal food (including nutrient supplements) is prepared, handled, and stored in compliance with halal, cleanliness, safety, and hygiene requirements.</p>
                                 <p>By following these standards, organizations can maintain the integrity of the HTAP and guarantee that the products delivered meet the halalan-toyyiban standards set by Islamic principles.</p>

                              </div> */}
                              {/* <a className="read-more" href="https://mysol.jsm.gov.my/search-catalogue?keyword=MS+2400-3%3A2010" target='_blank'>Read more</a> */}
                        </div>
                        <div className="blog-post-img">
                           <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <img src="/assets/img/maddh6.jpg" alt="img1" style={{ width: '20%', height: 'auto', margin: '10px' }} />
                              <img src="/assets/img/maddh1.jpg" alt="img2" style={{ margin: '10px' }} />
                              <img src="/assets/img/maddh2.jpg" alt="img3" style={{ margin: '10px' }} />
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <img src="/assets/img/maddh3.jpg" alt="img4" style={{ margin: '10px' }} />
                              <img src="/assets/img/maddh4.jpg" alt="img5" style={{ margin: '10px' }} />
                              <img src="/assets/img/maddh5.jpg" alt="img6" style={{ margin: '10px' }} />
                           </div>
                           </div>
                     </article>
               
               )

            } ) }

            </div>
            {/* <div className="row">
               <div className="col">
                  <nav className="m-25px-t app-pagenation">
                     <ul className="pagination">
                        <li className="page-item disabled">
                           <a className="page-link" href={anchor} tabIndex="-1"><i className="icofont-long-arrow-left"></i></a>
                        </li>
                        <li className="page-item"><a className="page-link" href={anchor}>1</a></li>
                        <li className="page-item active"><a className="page-link" href={anchor}>2</a></li>
                        <li className="page-item"><a className="page-link" href={anchor}>3</a></li>
                        <li className="page-item">
                           <a className="page-link" href={anchor}><i className="icofont-long-arrow-right"></i></a>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div> */}
         </div>
      </section>
      </div>
   );
  }
}

export default HTAP;
