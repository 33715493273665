import React from 'react';
import Contact from './components-home/Contact';
import FeatureOne from './components-home/About-section';
import Heroarea from './components-home/Heroarea';
import Pricing from './components-home/Pricing';
import Header from "../components/components-global/Header";


const Home = () => {

    return (
      <div>
      <Header />
      <Heroarea />
      <FeatureOne />
      {/* <Howitworks /> */}
      {/* <Services /> */}
      {/* <FeatureTwo /> */}
      {/* <Feature /> */}
      {/* <Preface /> */}
      {/* <Blog /> */}
      {/* <Screenshots /> */}
      <Pricing />
      {/* <Testimonials /> */}
      <Contact />
      </div>


    );
 
}



export default Home;
