import React, { useState } from 'react';
import homedata from '../../data/homedata.json';

const FeatureOne = () => {
  const [showMore, setShowMore] = useState(false);
  const { content, content2, content3, btn1label, imgurl1} = homedata.feature1;
  const paragraphs = content.split('\n\n');

  return (
    <section id="aboutUs" className=" featured-1 position-relative" style={{minHeight:'82vh'}}>
      {/* <div className="bordered-circle2"></div> */}
      <div className="container p-100px-t">
        
        <div className="row">
          
          <div className="col-sm-5 mx-auto d-block">
            <img className="moveUpDown" src={process.env.PUBLIC_URL + homedata.feature1.imgurl1} alt="" />
          </div>    
          <div className="col-sm-6">
            <div className="section-title">
              <h2 className="tlt1">{homedata.feature1.title}</h2>
            </div>
            <div style={{ textAlign: 'justify' }} >
              {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
              {showMore ? (
                <>
                  <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{
                    __html: content2.replace("DARUL:", "<b>DARUL:</b>")
                  }} />
                </>
              ) : (
                <p style={{ textAlign: 'justify' }}>{content2.substring(0, 0)}</p>
              )}

              {showMore ? (
                <>
                  <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{
                    __html: content3.replace("HIJRAH:", "<b>HIJRAH:</b>")
                  }} />
                </>
              ) : (
                <p style={{ textAlign: 'justify' }}>{content3.substring(0, 0)}</p>
              )}

            <button 
              onClick={() => setShowMore(!showMore)} 
              className="btn btn-default btn-filled animated-btn"
            >
              {showMore ?  'Tutup' : btn1label}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureOne;
