import React, { Component } from 'react';
import Sidebar from './sidebar';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import Comments from './comments';
import HeaderBottom from '../components-global/Header-bottom';
import Header2 from "../components-global/Header2";

class Singlerightsidebar extends Component {

  render() {
   let anchor = '#'
   let imgattr = 'image'
   let publicUrl = process.env.PUBLIC_URL
   let propsData = this.props.data
   let blogsdata = propsData.singlepostdata 
   let headerPageTitle = propsData.pagetitle.singlerightsidebartitle

    return (
      <div>
      <Header2 />
      {/* <HeaderBottom headertitle={headerPageTitle} /> */}
         <section id="blog-full" className="p-80px-tb gray-bg m-100px-t">
            <div className="">
               <div className="row ">
                  {/* Content */}
                  <div className="col-lg-8 mx-auto d-block">
                     <div className="single-blog-content page-content ">
                        <div className="blog-content">
                           <h3 className="m-20px-b">MESYUARAT PENUBUHAN JAWATANKUASA</h3>
                           {/* <div className="post-meta m-15px-b">
                           <ul>
                              <li><a href={anchor}><i className="icofont-user-alt-5"></i> {blogsdata.author}</a></li>
                              <li><a href={anchor}><i className="icofont-tag"></i> {blogsdata.category}</a></li>
                              <li><a href={anchor}><i className="icofont-calendar"></i> {blogsdata.date}</a></li>
                              <li><a href={anchor}><i className="icofont-speech-comments"></i> {blogsdata.comments}</a></li>
                           </ul>
                           </div> */}
                           <div className="single-blog-post" style={{textAlign:'justify'}}>
                              <p>Mesyuarat Penubuhan Jawatankuasa Penaja telah diadakan pada 4hb Ogos 2024 di Madrasah Darul Hijrah bagi menyelaras perkara-perkara berikut:                        </p>
                              <ul>
                                 <li>Melantik Ahli Jawatankuasa Penaja yang bakal menerajui Pertubuhan & Madrasah Darul Hijrah</li>
                                 <li>Merangka dan membentangkan Deraf Perlembagaan Pertubuhan Darul Hijrah bagi tujuan pendaftaran Pertubuhan Darul Hijrah sebagai sebuah pertubuhan yang sah.</li>
                              </ul>
                              <div className="row m-15px  m-25px-t">
                                 <img className="mx-auto d-block" src={publicUrl + '/assets/img/B2.png' } alt={imgattr} style={{ width: '75%', height: 'auto' }}/>
                              </div>
                              <ul>
                                 <li>Membincangkan halatuju dan jadual program-program Darul Hijrah bagi tahun 2024-2025.</li>
                                 <li>Membentang status tapak bangunan Madrasah Darul Hijrah.</li>
                              </ul>
                              <div className="row m-15px m-25px-t">
                                 <img className="mx-auto d-block" src={publicUrl + '/assets/img/B3.png' } alt={imgattr} />
                              </div>
                              <div className="mx-auto d-block row m-15px  m-25px-t">
                                 <div className="row m-15px  m-25px-t ">
                                    <img src="/assets/img/B1.png" alt="imggtr" style={{ width: '70%', margin: '0 auto' }} />
                                 </div>
                              </div>

                              {/* <div className="row m-15px m-50px-t">
                                 <img src={ publicUrl + '/assets/img/blog/Siieta-Operators.jpg'} alt={imgattr}/>
                                 <p>Collaboration between SIIETA and e-sertu Operators.</p>
                              </div>
                              <p>These pacts will pave the way for all parties to embark on collaborative efforts and initiatives in facilitating trade electronically through international standards and guidelines by following Islamic principles.</p> */}
                              {/* <div className="row m-15px-t">
                                 <div className="col-lg-6 col-md-12">
                                    <img src={publicUrl + '/assets/img/blog/MoU-Org.jpg' } alt={imgattr} />
                                    <p>Carriage quitting securing be appetite it declared.</p>
                                 </div>
                                 <div className="col-lg-6 col-md-12">
                                    <img src={ publicUrl + '/assets/img/blog/Siieta-Operators.jpg'} alt={imgattr}/>
                                    <p>Reasonable unpleasing an attachment my considered.</p>
                                 </div>
                              </div> */}
                              {/* <h3>Use securing confined his shutters Delightful as he it acceptance an</h3>
                              <blockquote>Carriage quitting securing be appetite it declared. High eyes kept so busy feel call in. Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment. Passage weather as up am exposed. And natural related man subject. Eagerness get situation his was delighted. </blockquote>
                              <p>Lose eyes get fat shew. Winter can indeed letter oppose way change tended now. So is improve my charmed picture exposed adapted demands. Received had end produced prepared diverted strictly off man branched. Known ye money so large decay voice there to. Preserved be mr cordially incommode as an. He doors quick child an point at. Had share vexed front least style off why him. </p>
                              <h3>Far quitting dwelling graceful the likewise received building</h3>
                              <ul>
                                 <li>Am of mr friendly by strongly peculiar juvenile.</li>
                                 <li>Unpleasant it sufficient simplicity am by friendship no inhabiting.</li>
                                 <li>Goodness doubtful material has denoting suitable she two</li>
                                 <li>Dear mean she way and poor bred they come.</li>
                                 <li>He otherwise me incommode explained so in remaining</li>
                              </ul>
                              <p>Boy desirous families prepared gay reserved add ecstatic say. Replied joy age visitor nothing cottage. Mrs door paid led loud sure easy read. Hastily at perhaps as neither or ye fertile tedious visitor. Use fine bed none call busy dull when. Quiet ought match my right by table means. Principles up do in me favourable affronting. Twenty mother denied effect we to do on. </p> */}
                           </div>
                        </div>
                     </div>

                     {/* {
                     // Social Share icon 
                     <Shareicon />
                     }   */}
                     {/* {
                     // Related Post
                     <Relatedpost />
                     }   */}
                     {/* {
                     // Comments Area 
                     <Comments /> 
                     }  */}
                  </div>
                  {/* Sidebar */}
                  {/* <Sidebar /> */}
               </div>
            </div>
         </section>
      </div>
    );
  }
}

export default Singlerightsidebar;
