import React, { Component } from 'react';


class HeaderBottom extends Component {
  render() {
   let HeaderTitle = this.props.headertitle;
    return (
      <section id="page-header" className="p-150px-t  p-20px-b sm-p-100px-t sm-p-40px-b">
         <div className="container md-p-50px-t ">
            <div className="row align-items-center ">
               <div className="col text-center ">
                    <div className="text-center"> 
                        <h2 className="white-text align-items-center">{HeaderTitle}</h2>
                        <a href="http://e-sertu.com/" target="_blank" className="btn btn-default btn-filled animated-btn m-10px-t" style={{borderRadius:'90px'}}>Visit Us</a>
                    </div>
               </div>
            </div>
         </div>
      </section>

    );
  }
}

export default HeaderBottom;
