import React, { Component } from 'react';
import Header2 from "../components-global/Header2";
import HeaderBottom from '../components-global/Header-bottom';

class Blogfull extends Component {

   render() {
      let anchor = '#'
      let propsData = this.props.data
      let blogs = propsData.blogs
      let headerPageTitle = propsData.pagetitle.blogfulltitle
      
      return (
      <div>
      <Header2 />
      <HeaderBottom/>
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
            { blogs.map( ( bloginfo,  ) => {

               let col = 'col-lg-6';
               if( bloginfo.fullwidth ) {
                  col = 'col-lg-12';
               }
               
               return (
                  
                     <article className="page-content">
                        <div className="blog-post-img row m-15px  m-25px-t">
                           <div className="mx-auto d-block">
                              <img className="mx-auto d-block" src="/assets/img/mudir.png" alt="imggtr" style={{ width: '50%', margin: '0 auto' }} />
                              <figcaption className="text-center m-20px-t" style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
                                 YBrs. Tuan Haji Mohd Yusof Bin Mohd Yunos
                              </figcaption>
                              <figcaption className="text-center" style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
                                 Pengerusi Pertubuhan Darul Hijrah Mudir Madrasah Darul Hijrah
                              </figcaption>
                           </div>
                        </div>
                        <div className="blog-content">
                              <h2>السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ</h2>
                              <h3>بِسْــــــــــــــــــمِ اﷲِالرَّحْمَنِ اارَّحِيم</h3>

                              <div className="single-blog-post " style={{textAlign:'justify'}}>
                                 <p>Alhamdulillah, rumah pusaka peninggalan keluarga Almarhum Tuan Haji Mohamad Yunos Ujang dan Hajjah Robiah Sahat telah berjaya di transformasi kepada sebuah bangunan yang bakal menjadi instistusi (DARUL) keagaaman bagi memberi manafaat khususnya kepada ahli keluarga dan secara amnya kepada ummah.</p>
                                 <p>Dengan terdirinya institusi ini, ia bakal menjadi titik mula peng(HIJRAH)an dan transformasi rohani ke arah kebaikan dan  kesempurnaan diri menurut nilai-nilai dan syariat serta tuntutan Islam.</p>
                                 
                                 <h4 className="m-40px-t">Penubuhan Madrasah Darul Hijrah ini bermatlamatkan perkara-perkara berikut:</h4>
                                 <ul>
                                    <li>Menjadi payung kepada pengumpulan ahli-ahli keluarga Haji Mohamad Yunos dan Hajjah Robiah Sahat demi mengeratkan silahturahim dan ukhwah sehingga generasi masa hadapan.</li>
                                    <li>Membantu ahli-ahli pertubuhan dari aspek pembangunan rohani dan ilmu-ilmu mengikut syariat Islam.</li>
                                    <li>Melahirkan generasi berilmu, bertaqwa dan berwawasan.</li>
                                    <li>Melahirkan sifat kepimpinan dikalangan ahli-ahli terutamanya daripada ahli-ahli keluarga Haji Mohamad Yunos dan Hajjah Robiah Sahat supaya meneruskan kesinambungan teraju Darul Hijrah.</li>
                                 </ul>
                              </div>
                              <div className="single-blog-post " style={{textAlign:'justify'}}>
                                 <p>Saya sebagai Pengerusi & Mudir institusi Darul Hijrah, berdoa kehadrat Illahi agar kewujudan Darul Hijrah akan memberikan manafaat kepada ummah & mencapai matlamat yang dicita-citakan.</p>
                                 <p>Wassalam.</p>
                              </div>
                              {/* <a className="read-more" href="https://www.halal.gov.my/v4/images/pdf/halalassurancesystem.pdf" target='_blank'>Read more</a> */}
                        </div>
                     </article>
               
               )

            } ) }

            </div>
            {/* <div className="row">
               <div className="col">
                  <nav className="m-25px-t app-pagenation">
                     <ul className="pagination">
                        <li className="page-item disabled">
                           <a className="page-link" href={anchor} tabIndex="-1"><i className="icofont-long-arrow-left"></i></a>
                        </li>
                        <li className="page-item"><a className="page-link" href={anchor}>1</a></li>
                        <li className="page-item active"><a className="page-link" href={anchor}>2</a></li>
                        <li className="page-item"><a className="page-link" href={anchor}>3</a></li>
                        <li className="page-item">
                           <a className="page-link" href={anchor}><i className="icofont-long-arrow-right"></i></a>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div> */}
         </div>
      </section>
      </div>
   );
  }
}

export default Blogfull;
