import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
// import 'swiper/swiper-bundle.min.css';
import Swiper from 'swiper/bundle';
import { HashLink as HLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

class Heroarea extends Component {

   componentDidMount() {
      new Swiper('.swiper-container', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
    

  render() {
    return (
      <section className="hero-area circle-wrap" style={{ marginTop:"50px", backgroundImage: 'url(./assets/img/dh-bg.png)', minHeight:'100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} >
         <div className="container">
            <div className="row full-height align-items-center ">
               <div className="col-md-6 p-50px-t p-50px-b md-p-10px-b  ">
                  <h2 className="text-capitalize m-25px-tb content" style={{fontSize:'40px', color:'black'}}>{homedata.heroarea.title}</h2>
                  <p className="m-25px-b" style={{fontSize:'30px', color:'black', fontWeight: 'bold'}}>{homedata.heroarea.content}</p>
                  <div className="hero-btn-wrapper">
                  <HLink className="btn btn-default  animated-btn btn-secondary" smooth to={homedata.heroarea.btn1url}>{homedata.heroarea.btn1label}</HLink>
                     {/* <a href={homedata.heroarea.btn1url} className="btn btn-default animated-btn">{homedata.heroarea.btn1label}</a> */}
                     {/* <a className="btn btn-default btn-default-outline animated-btn" href={homedata.heroarea.btn2url}>{homedata.heroarea.btn2label}</a> */}
                  </div>
               </div>
               <div className="col-md-6 p-100px-t p-50px-b md-p-10px-t ">
                  <img className="hero-mock m-100px-l  md-m-0px-l" style={{height:"400px", width:"400px"}} src={process.env.PUBLIC_URL+homedata.heroarea.imgurl1} alt="Hero mockup"/>
               </div>
            </div>
         </div>
      </section>

         // <div>
         //    {/* First Section */}
         //    <section className="hero-area circle-wrap">
         //       <div className="swiper-container">
         //          <div className="swiper-wrapper">
         //             {/* First Slide */}
         //             <div className="swiper-slide first-slide" style={{ backgroundImage: 'url(./assets/img/Hero-bg.png)', minHeight: '100vh',backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
         //                {/* Content for the first slide */}
         //                <div className="container">
         //                   <div className="row full-height align-items-center ">
         //                      <div className="col-md-6 p-100px-t p-50px-b md-p-10px-b  ">
         //                         <h2 className="text-capitalize m-25px-tb " style={{fontSize:'37px'}}>{homedata.heroarea.title}</h2>
         //                         <p className="m-25px-b">{homedata.heroarea.content}</p>
         //                         <div className="hero-btn-wrapper">
         //                         <HLink className="btn btn-default btn-default-outline animated-btn" smooth to={homedata.heroarea.btn1url}>{homedata.heroarea.btn1label}</HLink>
         //                            {/* <a href={homedata.heroarea.btn1url} className="btn btn-default animated-btn">{homedata.heroarea.btn1label}</a> */}
         //                            {/* <a className="btn btn-default btn-default-outline animated-btn" href={homedata.heroarea.btn2url}>{homedata.heroarea.btn2label}</a> */}
         //                         </div>
         //                      </div>
         //                      <div className="col-md-6 p-100px-t p-50px-b md-p-10px-t ">
         //                         <img className="hero-mock m-100px-l  md-m-0px-l" style={{height:"400px", width:"400px"}} src={process.env.PUBLIC_URL+homedata.heroarea.imgurl1} alt="Hero mockup"/>
         //                      </div>
         //                   </div>
         //                </div>
         //             </div>
         //             <div className="swiper-slide second-slide" style={{backgroundImage: 'url(./assets/img/BannerRaya-02.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center ' }}>
         //                {/* Content for the first slide */}
         //                <div className="container">
         //                   <div className="row full-height align-items-center ">
         //                      <div className="col-md-6 p-100px-t p-50px-b md-p-10px-b  ">
                                 
         //                      </div>
                              
         //                   </div>
         //                </div>
         //             </div>
         //          </div>

         //          {/* Navigation Buttons for the First Section */}
         //          <div className="swiper-button-next"></div>
         //          <div className="swiper-button-prev"></div>
         //       </div>
         //    </section>
         // </div>


    );
  }
}

export default Heroarea;
